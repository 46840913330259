import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {Alert, Snackbar, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import * as yup from 'yup';
import {useFormik} from "formik";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from "@mui/material/Typography";

const validationScheme = yup.object({
    name: yup
        .string('Введіть своє ім\'я')
        .required('Ім\'я обов\'язкове'),
    phone: yup
        .number('Введіть контактний номер')
        .required('Контактний номер обов\'язковий'),
    message: yup
        .string('Введіть повідомлення')
});

function RequestForm() {
    const [isSend, setIsSend] = useState(false);
    const [isError, setIsError] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: ''
        },
        validationScheme: validationScheme,
        onSubmit: async (values) => {
            const {name, phone, message} = values;
            try {
                const URLParams = new URLSearchParams({
                        "entry.427379857": name,
                        "entry.897326314": phone,
                        "entry.825029321": message || 'Пусто'
                    },
                    {
                        mode: "no-cors"
                    });

                await fetch(
                    'https://docs.google.com/forms/d/e/1FAIpQLSeCrpjTuXiAA1CPOXm0fp4t1KiTpHHnprpxzwjdnZDyLp_Bng/formResponse?' + URLParams
                    ,
                    {
                        mode: 'no-cors'
                    })
                    .then(() => {
                        setIsSend(true);
                    })
            } catch (e) {
                setIsError(true);
            }
        }
    });

    const handleSnackbarClose = () => setIsError(false);

    if (isSend) {
        return (
            <Box
                display='flex'
                alignContent='center'
                justifyContent='center'
                textAlign='center'
                gap={2}
            >
                <CheckCircleOutlineIcon color='success' fontSize='large'/>
                <Typography
                    color='success'
                    pt={0.4}
                >
                    Ви успішно залишили за'явку!
                </Typography>
            </Box>
        );
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display='flex' flexDirection='column' gap={1}>
                <TextField
                    id='name'
                    label="Ваше ім'я"
                    variant="outlined"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    required
                    fullWidth
                />
                <TextField
                    id='phone'
                    name='phone'
                    label="Ваш номер телефону"
                    variant="outlined"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    required
                    fullWidth
                />
                <TextField
                    id='message'
                    label="Повідомлення"
                    variant="outlined"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    minRows={4}
                    maxRows={4}
                    fullWidth
                    multiline
                />
                <Button variant="contained" type='submit'>Зв'язатися</Button>
            </Box>
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                open={isError}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    variant="filled"
                    sx={{width: '100%'}}
                >
                    Щось пішло не так, спробуйте ще раз, або зверніться за номером!
                </Alert>
            </Snackbar>
        </form>
    );
}

export default RequestForm;