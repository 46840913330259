import React from 'react';
import Box from "@mui/material/Box";
import {Avatar, Typography} from "@mui/material";
import {reviewsData} from "../../settings";
import HireButton from "../../Components/HireButton/HireButton";

function ReviewsBlock() {
    return (
        <Box textAlign='center' id='reviews' pb={6}>
            <Typography
                variant='h4'
                component='h2'
                textAlign='center'
                sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                }}
            >
                Що кажуть люди
            </Typography>
            <Box
                display='flex'
                flexDirection={{xs: 'column', sm: 'row'}}
                alignItems='center'
                justifyContent='space-around'
                gap={2}
                py={2}
                px={'2px'}
                sx={{overflowX: 'auto'}}
            >
                {reviewsData.map((review, index) => (
                    <Box display='flex' flexDirection='column' key={index} sx={{width: 1, alignSelf: 'stretch'}}>
                        <Box display='flex' flexDirection='column' alignItems='center' gap={2} py={2}>
                            <Avatar alt={review.name} src={review.image}
                                    sx={{width: 128, height: 128, boxShadow: (theme) => theme.shadows[3]}}/>
                            <Typography variant="h3" sx={{fontSize: '1.25rem', fontWeight: 700}}>
                                {review.name}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                borderRadius: 5,
                                backgroundColor: (theme) => theme.palette.primary.main,
                                boxShadow: (theme) => theme.shadows[3],
                                flex:1
                            }}
                            p={1}
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                        >
                            <Typography variant='body1'
                                        sx={{
                                            color: '#fff',
                                            fontStyle: 'italic',
                                        }}>
                                "{review.text}"
                            </Typography>
                        </Box>
                    </Box>
                ))}

            </Box>
            <HireButton/>
        </Box>
    );
}

export default ReviewsBlock;
