import React from 'react';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";

function Footer() {
    return (
        <Paper
            sx={{
                background: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.common.white,
            }}
        >
            <Container maxWidth='md'>
                <Box textAlign='center' py={2}>
                    <Typography>
                        Олексій Золотоголовий @2024
                    </Typography>
                </Box>
            </Container>
        </Paper>
    );
}

export default Footer;
