import React from 'react';
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import {casesData} from '../../settings';

const CaseItem = ({thiscase, index}) => (
    <Grid container spacing={2} key={index} pb={4}>
        <Grid item xs={12} sm={6} order={{xs: 1, sm: (index + 1 % 2 === 2 ? 2 : 1)}} pl={2} display='flex'>
            <Box sx={{
                backgroundImage: `url(${thiscase.bgimage})`,
                width: 1,
                objectFit: 'cover',
                alignSelf: 'stretch',
                borderRadius: 5,
                minHeight: 300
            }}/>
        </Grid>
        <Grid container item xs={12} sm={6} spacing={2} order={1}>
            <Grid item xs={12}>
                <Typography variant='h5'
                            sx={{color: (theme) => theme.palette.warning.main}}>Кейс {index + 1}</Typography>
                <Typography variant='h6' component='h1'>{thiscase.name}</Typography>
                <Typography variant='body1' sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: '#fff',
                    borderRadius: 5,
                    padding: 2
                }}>
                    {thiscase.text}
                </Typography>
            </Grid>
            <Grid item xs={12} display='flex' alignItems='flex-end'>
                <TableContainer component={Paper} sx={{width: 1}}>
                    <Table size='small'>
                        <TableBody>
                            <TableRow>
                                <TableCell variant='head' component='th'>Результативність</TableCell>
                                <TableCell align='right'>{thiscase.results}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head' component='th'>Охоплення</TableCell>
                                <TableCell align='right'>{thiscase.reach}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head' component='th'>Покази</TableCell>
                                <TableCell align='right'>{thiscase.impressions}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head' component='th'>Ціна за результат</TableCell>
                                <TableCell align='right'>{thiscase.cpr}$</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant='head' component='th'>Сума витрат</TableCell>
                                <TableCell align='right'>{thiscase.spent}$</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </Grid>
);

function CasesBlock() {
    return (
        <Box pb={4} id='cases'>
            <Typography
                variant='h4'
                component='h2'
                textAlign='center'
                sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                }}
                pb={4}
            >
                Мої проєкти
            </Typography>
            {casesData.map((thiscase, index) => (
                <CaseItem key={index} thiscase={thiscase} index={index}/>
            ))}
        </Box>
    );
}

export default CasesBlock;
