import React from 'react';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Paper} from "@mui/material";

import {servicesData} from "../../settings";
import HireButton from "../../Components/HireButton/HireButton";

function ServicesBlock() {
    return (
        <Box
            textAlign='center'
            id='services'
            py={6}
        >
            <Typography
                variant='h4'
                component='h2'
                textAlign='center'
                sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                }}
            >
                Що я пропоную
            </Typography>
            <Box
                display='flex'
                flexDirection={{xs: 'column', sm: 'row'}}
                alignItems='center'
                justifyContent='space-around'
                gap={2}
                py={2}
                px={'2px'}
                sx={{
                    overflowX: 'auto',
                }}
            >
                {servicesData.map((service, index) => (
                    <Paper elevation={4} key={index} sx={{width: 1, alignSelf: 'stretch', borderRadius: 5}}>
                        <img
                            src={service.image}
                            height={250}
                            alt={service.alt}
                        />
                        <Box sx={{padding: '0'}}>
                            <Typography variant="h2"
                                        sx={{
                                            textTransform: 'uppercase',
                                            fontSize: '1.25rem',
                                            fontWeight: 700,
                                            color: '#fff',
                                            padding: .5,
                                            backgroundColor: (theme) => theme.palette.primary.main
                                        }}>{service.title}</Typography>
                            <Typography variant='body1' p={1}>{service.description}</Typography>
                        </Box>
                    </Paper>
                ))}

            </Box>
            <HireButton/>
        </Box>
    );
}

export default ServicesBlock;