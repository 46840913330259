import React from 'react';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MyPhoto from '../../assets/MyPhoto.png';

import HireButton from "../../Components/HireButton/HireButton";
import {Grid} from "@mui/material";

const styles = {
    aboutBox: {
        textAlign: {xs: 'center', sm: 'left'},
        pt: 4,
        pb: 2,
    },
    heading1: {
        fontSize: '1.25rem',
        color: (theme) => theme.palette.primary.main,
        fontWeight: 700,
    },
    heading2: {
        fontSize: '1.8rem',
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    heading3: {
        fontSize: '1.25rem',
        color: (theme) => theme.palette.warning.main,
        fontWeight: 700,
    },
    description: {
        marginTop: 1,
    },
    imageContainer: {
        position: 'relative',
        width: '100%',
        ':before': {
            content: '""',
            display: 'block',
            paddingTop: '100%'
        }
    },
    imageBox: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: (theme) => theme.palette.primary.main,
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        height: '100%',
        transform: 'translateY(10%)'
    },
};

function AboutBlock() {
    return (
        <Grid sx={styles.aboutBox} id='aboutme' container alignItems='center'>
            <Grid item xs={12} sm={6} order={1}>
                <Typography variant='h6' sx={styles.heading1}>
                    Привіт, я
                </Typography>
                <Typography variant='h2' sx={styles.heading2}>
                    Золотоголовий Олексій
                </Typography>
                <Typography variant='h1' sx={styles.heading3}>
                    маркетолог
                </Typography>
                <Typography sx={styles.description}>
                    Я досвідчений маркетолог та експерт з брендінгу. За короткий період часу вдалося здобути досвід,
                    працюючи
                    над різнобічними проектами в сфері маркетингу. Моя мета - допомагати людям заробляти за допомогою
                    реклами.
                </Typography>
                <HireButton/>
            </Grid>
            <Grid item xs={12} sm={6} sx={styles.imageContainer} order={{xs: 0, sm: 2}}>
                <Box sx={styles.imageBox}>
                    <img src={MyPhoto} alt='Маркетолог, таргетолог, Біла Церква' style={styles.image}/>
                </Box>
            </Grid>
        </Grid>
    );
}

export default AboutBlock;
