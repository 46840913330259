import React from "react";

import {CssBaseline} from "@mui/material";
import Container from "@mui/material/Container";

import Header from "./Components/Header/Header";
import AboutBlock from "./Blocks/AboutBlock/AboutBlock";
import ServicesBlock from "./Blocks/ServicesBlock/ServicesBlock";
import ContactsBlock from "./Blocks/ContactsBlock/ContactsBlock";
import Footer from "./Components/Footer/Footer";
import ReviewsBlock from "./Blocks/ReviewsBlock/ReviewsBlock";
import CasesBlock from "./Blocks/CasesBlock/CasesBlock";


function App() {
    return (
        <React.Fragment>
            <CssBaseline/>
            <Header/>
            <Container maxWidth='md'>
                <AboutBlock/>
                <ServicesBlock/>
                <ReviewsBlock/>
                <CasesBlock/>
                <ContactsBlock/>
            </Container>
            <Footer/>
        </React.Fragment>
    );
}

export default App;
