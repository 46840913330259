import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import RequestForm from "../../Components/RequestForm/RequestForm";
import {useMediaQuery, useTheme} from "@mui/material";

const ContactHeader = () => (<Typography
    variant='h4'
    component='h2'
    textAlign={{xs: 'center', sm: 'start'}}
    sx={{
        color: (theme) => theme.palette.primary.main,
        fontWeight: 700,
        textTransform: 'uppercase',
    }}
>
    Контакти
</Typography>);

function ContactsBlock() {
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='space-around'
            flexDirection={{xs: 'column-reverse', sm: 'row'}}
            id='contacts'
            pb={8}
        >
            <Box flexGrow={1} order={{xs: 5}}>
                {
                    isXS && <ContactHeader/>
                }
            </Box>
            <Box flexGrow={1} sx={{
                xs: {
                    width: '100%',
                },
                sm: {
                    width: '50%'
                }
            }}>
                {
                    !isXS && <ContactHeader/>
                }
                <Typography pt={{xs: 2, sm: 0}} pb={1}>
                    Залиште повідомлення і я зв'яжуся з Вами для обговорення проєкту.
                </Typography>
                <Box
                    display='flex'
                    gap={2}
                    pb={1}
                >
                    <AlternateEmailIcon color='warning'/>
                    <Typography component='a' href='mailto:zolotoho@gmail.com'
                                sx={{
                                    textDecoration: 'none',
                                    color: (theme) => theme.palette.warning.main,
                                    fontWeight: 700,
                                    '&:visited': {
                                        textDecoration: 'none',
                                        color: (theme) => theme.palette.warning.main,
                                    }
                                }}>
                        zolotoho@gmail.com
                    </Typography>
                </Box>
                <Box
                    display='flex'
                    gap={2}
                >
                    <PhoneIphoneIcon color='warning'/>
                    <Typography component='a' href='tel:+380664072645'
                                sx={{
                                    textDecoration: 'none',
                                    color: (theme) => theme.palette.warning.main,
                                    fontWeight: 700,
                                    '&:visited': {
                                        textDecoration: 'none',
                                        color: (theme) => theme.palette.warning.main,
                                    }
                                }}>
                        +380664072645
                    </Typography>
                </Box>
            </Box>
            <Box flexGrow={1} sx={{width: {xs: '100%', sm: '50%'}}}>
                < RequestForm/>
            </Box>
        </Box>
    );
}

export default ContactsBlock;