import ServiceCardImage1 from "./assets/Service1.jpg";
import ServiceCardImage2 from "./assets/Service2.jpg";
import ServiceCardImage3 from "./assets/Service3.jpg";

import ReviewCardImage1 from "./assets/Review1.jpg";
import ReviewCardImage2 from "./assets/Review2.jpg";
import ReviewCardImage3 from "./assets/Review3.jpg";

import CaseImage1 from './assets/Case1.jpg';
import CaseImage2 from './assets/Case2.jpg';
import CaseImage3 from './assets/Case3.jpg';

export const pages = [
    {
        text: 'Про мене',
        link: '#aboutme'
    },
    {
        text: 'Послуги',
        link: '#services',

    },
    {
        text: 'Відгуки',
        link: '#reviews'
    },
    {
        text: 'Кейси',
        link: '#cases',

    },
    {
        text: 'Контакти',
        link: '#contacts'
    }
];

export const servicesData = [
    {
        title: 'Консультація',
        description: 'Оптимізація: ведення сторінки, оформлення, переписки та інше. Максимально ефективне виявлення слабких місць у Вашому бізнесі',
        image: ServiceCardImage1,
        alt: 'Консультація',
    },
    {
        title: 'Разове налаштування',
        description: 'Разове налаштування реклами: швидкий та точний пуск рекламної кампанії. Максимізація ефективності для Вашого бізнесу. Забезпечення якісного старту Вашої рекламної кампанії!',
        image: ServiceCardImage2,
        alt: 'Разове налаштування',
    },
    {
        title: 'Повне ведення',
        description: 'Повне ведення реклами: від створення стратегії до постійного моніторингу та адаптації. Отримайте найвищу результативність для Вашого бізнесу. Довірте мені всі етапи Вашої рекламної кампанії!',
        image: ServiceCardImage3,
        alt: 'Повне ведення',
    },
];

export const reviewsData = [
    {
        name: 'Вікторія Приймак',
        text: 'Дякую за співпрацю! Отримала гарний результат по новим клієнтам. Легкість в роботі та індивідуальний підхід. Цілком задоволена співпрацею і результатом!',
        image: ReviewCardImage1,
    },
    {
        name: 'Світлана Павловська',
        text: 'Хочу подякувати Вам за роботу. Бачу результати реклами, не лише підписники, а й реальні клієнти ) Окремо дякую, за креативи та швидку відповідь ))',
        image: ReviewCardImage2,
    },
    {
        name: 'Анатолій Войтенко',
        text: 'Дуже велике дякую! Очікував отримати менше чим отримав) Щиро дякую, ви найкращий!',
        image: ReviewCardImage3,
    },
];

export const casesData = [
    {
        results: 208,
        reach: 38942,
        impressions: 117161,
        cpr: 1.04,
        spent: 200,
        name: 'Салон краси',
        text: <>Завдяки гарно підібраним креативам, а також точному влучанню в аудиторію. З таким
            невеликим бюджетом в <b>200$</b> вдалося отримати <b>208</b> нові
            заявки з конверсією <b>2.23%</b>, які згодом з конверсією у <b>21% </b>
            вдалося конвертувати в нових клієнтів, та загалом залучити <b>43</b> нових
            клієнти.
        </>,
        bgimage: CaseImage1,
    },
    {
        results: 35,
        reach: 9768,
        impressions: 20959,
        cpr: 1.42,
        spent: 50,
        name: 'Виробництво шпону',
        text: <>Після початку співпраці першим ділом було покращено профіль, а саме упакований візуал, та нові пости які охоплюють потреби клієнтів. Через деякий час запуск компаній з оновленим профілем, дав змогу отримувати
            заявки ціною всього в <b>1.42$</b>. Кількість отриманих клієнтів, на жаль розголошувати не можу.</>,
        bgimage: CaseImage2,
    },
    {
        results: 276,
        reach: 30925,
        impressions: 91241,
        cpr: 0.72,
        spent: 200,
        name: 'Магазин косметики',
        text: <>Ціль реклами була розкрутка продуктів власного виробництва, з доволі великим чеком. Методом підбору було виявлено найбільш популярні позиції, на які й давалась основна частина бюджету. Вдалося досягти <b>276</b> повідомлень за ціною <b>0.72$</b>.</>,
        bgimage: CaseImage3,
    },
];