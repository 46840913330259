import React from 'react';
import Button from "@mui/material/Button";

function HireButton({color = 'primary'}) {
    return (
        <Button component='a' href='#contacts' variant='contained' color={color}>
            Зв'язатися зі мною
        </Button>
    );
}

export default HireButton;